@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');
@font-face {
  font-family: 'Neue-Light';
  src: local('Neue-Light'), url(./font/Neue-Light.otf) format('opentype');
}
@font-face {
  font-family: 'Neue-Reg';
  src: local('Neue-Reg'), url(./font/Neue-Reg.otf) format('opentype');
}
span{
  font-family: 'Neue-Light' !important;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 2px;
}
p{
  font-family: 'Neue-Light' !important;
  font-size: 21px;
  letter-spacing: 2px;
}
.card-text{
  font-family: 'Neue-Light' !important;
}
.card-text p b{
  font-family: "Lora", serif;
  font-size: 16px !important;
  letter-spacing: 2px;
}
h1{
  font-family: "Lora", serif;
  font-size: 24px !important;
  letter-spacing: 1.4px;
}
h2{
  font-weight: 300;
  font-style: normal;
  font-size: 18px !important;
}
h2 b{
  font-family: 'Neue-Reg';
  font-style: normal;
  font-size: 22px !important;
  letter-spacing: 2px;
}
.aboutTitle{
  padding-top: 75px;
}
.aboutTitle h2{
  font-family: "Lora", serif;
  font-size: 38px !important;
  font-weight: 300;
  letter-spacing: 2px;
}
.aboutText p{
  font-family: 'Neue-Light' !important;
  font-weight: 300;
  font-size: 21px;
  letter-spacing: 2px;
}
.aboutTitle h2.sub{
  font-size: 35px !important;
  letter-spacing: 2px;
}
.aboutTopTitle{
  font-size: 40px !important;
  font-family: "Cormorant Garamond", serif;
  font-weight: 900;
  font-style: normal;
  text-align: center;
  letter-spacing: 2px;
  padding-bottom: 130px;
}
.aboutTopSection{
  padding-top: 150px;
  padding-bottom: 180px;
}
.aboutBottomSection{
  padding-bottom: 130px;
}
.contactSection{
  padding-top: 150px;
  padding-bottom: 150px;
  margin-bottom: -100px;
  height: 100vh;
}
.contactTextSection{
  text-align: center;
}
.cardContainer{
  width: 65%;
  margin: 0 auto;
}
.card-title{
  font-family: 'Neue-Light' !important;
  font-size: 20px !important;
  letter-spacing: 1.8px;
}
.nav-link {
  display: inline-block;
  position: relative;
  color: #000000;
  margin-left: 16px !important;
  font-family: 'Neue-Light' !important;
  font-weight: 900 !important;
  font-size: 14px !important;
  letter-spacing: 3px !important;
}
.navBlocker{
  width: 100%;
  height: 66.19px;
}
.nav-link::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.nav-link:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
.logo{
  width: 150px;
}
.navigationContainer{
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.heroContent{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer{
  width: 100%;
  background-color: black;
  padding: 40px;
  z-index: 9999999 !important;
}
.topImgContent{
  width: 450px;
}
.movieHero{
  height: calc(100vh - 65px);
  width: 100%;
  object-fit: fill; 
  margin-top: 65px; 
}
.commercialHero{
  height: calc(100vh - 65px);
  width: 100%;
  object-fit: fill; 
  margin-top: 65px;
}
.movieHeroContainer{
  width: 100%;
  background-color: #000;
  overflow: hidden !important; 
}
.galleryImg img{
  max-height: 480px;
  overflow: hidden !important;
  padding-top: 0px;
  transition: transform .4s; 
  width: 100%;
}
.galleryImg {
  background-color: #000 !important;
}
.galleryImg:hover img{
  transform: scale(1.2);
  cursor: pointer;
}
.galleryImg {
  padding: 0px !important;
  border-radius: 0px !important; 
}
.movieImgContainer{
  overflow: hidden !important;
  background-color: #000;
}
.imgHover{
  transition: transform .4s; 
}
.imgHover:hover{
  transform: scale(1.2); 
  cursor: pointer;
}
.ten{
  background-image: url("../src/img/commercial/Com-2.jpg");
  background-size: contain;
  transition: transform .4s;
  background-repeat: no-repeat; 
}
.ten:hover {
  transform: scale(1.2); 
  cursor: pointer;
}
.eleven{
  background-image: url("../src/img/commercial/Com-5.jpg");
  background-size: contain;
  transition: transform .4s;
  background-repeat: no-repeat; 
}
.eleven:hover {
  transform: scale(1.2); 
  cursor: pointer;
}
.twelwe{
  background-image: url("../src/img/commercial/Com-6.jpg");
  background-size: contain;
  transition: transform .4s;
  background-repeat: no-repeat; 
}
.twelwe:hover {
  transform: scale(1.2); 
  cursor: pointer;
}
.thirteen{
  background-image: url("../src/img/commercial/Com-7.jpg");
  background-size: contain;
  transition: transform .4s;
  background-repeat: no-repeat; 
}
.thirteen:hover {
  transform: scale(1.2); 
  cursor: pointer;
}
.fourteen{
  background-image: url("../src/img/commercial/Com-8.jpg");
  background-size: contain;
  transition: transform .4s;
  background-repeat: no-repeat; 
}
.fourteen:hover {
  transform: scale(1.2); 
  cursor: pointer;
}
.fifthteen{
  background-image: url("../src/img/commercial/Com-4.jpg");
  background-size: contain;
  transition: transform .4s;
  background-repeat: no-repeat; 
}
.fifthteen:hover {
  transform: scale(1.2); 
  cursor: pointer;
}
.sixteen{
  background-image: url("../src/img/commercial/Com-1.jpg");
  background-size: contain;
  transition: transform .4s;
  background-repeat: no-repeat; 
}
.sixteen:hover {
  transform: scale(1.2); 
  cursor: pointer;
}
.seventeen{
  background-image: url("../src/img/commercial/Com-3.jpg");
  background-size: contain;
  transition: transform .4s;
  background-repeat: no-repeat; 
}
.seventeen:hover {
  transform: scale(1.2); 
  cursor: pointer;
}
.footerLogo{
  width: 300px;
  margin: 0 auto;
}
@media screen and (min-width: 1920px) {
  .contactSection{
    padding-top: 150px;
    padding-bottom: 150px;
    margin-bottom: -150px;
    height: 100vh;
  }
}
@media screen and (max-width: 1400px) {
  .cardContainer{
    width: 80%;
  }
}
@media screen and (max-width: 1400px) {
  .cardContainer{
    width: 90%;
  }
}
@media screen and (max-width: 991px) {
  .aboutTitle{
    padding-top: 0px;
    padding-bottom: 50px;
  }
  .aboutTopTitle{
    padding-bottom: 50px;
  }
  .aboutTopSection{
    padding-top: 50px;
    padding-bottom: 50px;
  } 
  .aboutTitle.sub{
    padding-bottom: 50px !important;
  }
  .cardContainer{
    margin-bottom: 50px;
  }
  .contactSection{
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: -100px;
  }
  .mobMB{
    margin-bottom: 20px;
  }
  .nav-link{
    line-height: 35px;
    font-size: 13px !important;
  }
  .navbar-nav{
    border-top:1px solid black;
    margin-top: 10px;
  }
  .cardContainer{
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  .topImgContent{
    width: 260px;
  }
  .contactTextSection{
    padding-left: 20%;
    padding-right: 20%;
  }
  .movieHero{
    height: calc(100vh - 65px);
    width: calc(100% + 200px) !important;
  }
  .commercialHero{
    height: calc(100vh - 65px);
    width: calc(100% + 200px) !important;
  }
  .footerLogo{
    width: 200px;
  }
  .cardContainer{
    width: 70%;
  }
}
@media screen and (max-width: 576px) {
  .logo{
    width: 140px;
  }
  .aboutTitle h2{
    font-size: 30px !important;
  }
  .aboutTitle h2{
    font-size: 30px !important;
  }
  .aboutTitle h2.sub{
    font-size: 28px !important;
  }
  .aboutTopTitle{
    font-size: 30px !important;
  }
  .aboutTopSection{
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 15px !important;
  }
  .aboutText p{
    font-family: 'Neue-Light' !important;
    font-weight: 300;
    font-size: 18px;
    letter-spacing: 1.3px;
  }
  p {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 16px;
    margin: 0 !important;
  }
  .contactTextSection{
    padding-left: 10%;
    padding-right: 10%;
  }
  .commercialHero{
    width: calc(100% + 500px) !important;
    margin-left: -270px
  }
  .cardContainer{
    width: 80%;
  }
}
@media screen and (max-width: 400px) {
  .card{
    width: 80% !important;
  }
  .contactTextSection{
    padding-left: 5%;
    padding-right: 5%;
  }
}